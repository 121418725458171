// Fonts
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
@import url('https://use.typekit.net/dgj3gvv.css');

// Material Icons
@import 'material-icons/iconfont/filled.css';
@import 'material-icons/iconfont/round.css';
@import 'material-icons/iconfont/outlined.css';

// Bootstrap
@import 'bootstrap/scss/bootstrap-utilities.scss';

// Keen Slider
@import 'keen-slider/keen-slider.min.css';

// App styles
@import './styles/main.scss';