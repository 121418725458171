@import "colors";

$headings-number: 5;
$multiplier: 0.2;

$sizes: (
  h1: 3rem,
  h2: 2.5rem,
  h3: 2.1rem,
  h4: 1.8rem,
  h5: 1.5rem,
  h6: 1.3rem
);

@mixin generate-typography-headers {
  @each $tag, $size in $sizes {
    #{$tag} {
      font-size: $size;
      line-height: 1.3em;
      font-weight: 500;
      margin: 0 0 1em 0;
    }
  }

  @each $tag, $size in $sizes {
    .#{$tag} {
      font-size: $size;
      line-height: 1.3em;
      font-weight: 500;
      margin: 0 0 1em 0;
    }
  }
}

@mixin generate-typography-colors {
  @each $colorName, $color in $colors {
    .text-#{$colorName} {
      color: $color !important;
    }
  }
}
