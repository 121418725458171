$media: (
  "extra-small": 360px,
  "small": 576px,
  "medium": 768px,
  "large": 1367px,
);

@mixin media-extra-small($type: "min") {
  @if $type == "min" {
      @media (min-width: map-get($media, "extra-small")) {
        @content;
      }
  } @else if $type == "max" {
      @media (max-width: map-get($media, "extra-small")) {
        @content;
      }
  }
}

@mixin media-small($type: "min") {
  @if $type == "min" {
    @media (min-width: map-get($media, "small")) {
      @content;
    }
  } @else if $type == "max" {
      @media (max-width: map-get($media, "small")) {
        @content;
      }
  }
}

@mixin media-medium($type: "min") {
  @if $type == "min" {
    @media (min-width: map-get($media, "medium")) {
      @content;
    }
  } @else if $type == "max" {
      @media (max-width: map-get($media, "medium")) {
        @content;
      }
  }
}

@mixin media-large($type: "min") {
  @if $type == "min" {
    @media (min-width: map-get($media, "large")) {
      @content;
    }
  } @else if $type == "max" {
      @media (max-width: map-get($media, "large")) {
        @content;
      }
  }
}

@mixin media($type, $size) {
  @if $type == "min" {
    @media (min-width: $size) {
      @content;
    }
  } @else if $type == "max" {
      @media (max-width: $size) {
        @content;
      }
  }
}