// COLORS
$white: #fff;
$black: #222;

$gray-1: #F9F9F9;
$gray-2: #F1F1F1;
$gray-3: #DFDFDF;
$gray-4: #C3C3C3;
$gray-5: #7A7A7A;
$gray-6: #676767;
$gray-7: #EAEAEA;

$bg-light-1: #FCFCFC;
$bg-ligth-2: #F5FBFF;
$bg-ligth-3: #EEF3F4;

$primary: #6FC137;
$secondary: #F69E05;
$tertiary: #11B2C2;
$self-highlight: #E7F4DD;

$danger: #F11616;
$info: $tertiary;
$success: $primary;
$warning: $secondary;
$default: $gray-3;

$colors: (
    white: $white,
    black: $black,

    gray-1: $gray-1,
    gray-2: $gray-2,
    gray-3: $gray-3,
    gray-4: $gray-4,
    gray-5: $gray-5,
    gray-6: $gray-6,

    bg-light-1: $bg-light-1,
    bg-light-2: $bg-ligth-2,
    bg-light-3: $bg-ligth-3,

    primary: $primary,
    secondary: $secondary,
    tertiary: $tertiary,

    danger: $danger,
    info: $info,
    success: $success,
    warning: $warning,

    default: $default
);
