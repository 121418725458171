@import 'mixins/typography';
@import 'mixins/media';
@import 'variables';
@import 'colors';

// Main
html, body, form, fieldset, table, tr, td, img {
    margin: 0;
    padding: 0;
    font-family: Roboto, Arial;
}

input, button, select, textarea, optgroup, option {
    font-family: inherit;
    font-size: inherit;
    font-style: inherit;
    font-weight: inherit;
}

body {
    font-size: 16px;
}

@include generate-typography-headers;
@include generate-typography-colors;


.no-select {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
  }

// Table

table {
    border-spacing: 4px;

    tr:hover td {
        background-color: $gray-1;
    }

    th {
        background-color: $gray-1;
        font-weight: 500;
    }

    th, td {
        padding: .6rem .8rem;
        color: $gray-6;
        border-radius: 6px;
        border: 2px solid $gray-2;
        transition: background-color $transition-time;
    }
}

.rws-table {
    width: 100%;
    max-width: 100%;
    overflow-y: auto;
    
    @include media-medium(max) {
        table {
            border-spacing: 2px;

            th, td {
                padding: 6px 10px;
            }
        }
    }
}

// Links
a {
    color: $tertiary;
    cursor: pointer;
    text-decoration: none;
    transition: color $transition-time;

    @media (hover:hover) {
        &:hover {
            color: lighten($tertiary, 20);
        }
    }
}

a.link {
    font-weight: 500;
    text-decoration: underline;
}

@media (hover:hover) {
    a:hover {
        color: blacken($tertiary, 10%);
    }
}

.cursor-pointer {
    cursor: pointer!important;
}

// Inputs autofill
input:-webkit-autofill {
    -webkit-background-clip: text;
    background-clip: text;
}

// tooltip directive
.app-tooltip {
    position: absolute;
    max-width: 260px;
    width: max-content;
    display: flex;
    font-size: .9rem;
    color: $gray-6;
    padding: 6px 12px;
    background: $white;
    border-radius: 4px;
    border: 2px solid $gray-3;
    z-index: 400;
    transition: opacity $transition-time;
    opacity: 0;
    box-shadow: 0 0 12px 0 rgba(0,0,0,0.1);
}

.app-tooltip-show {
    opacity: 1;
}

.tooltip-icon {
    color: $gray-4!important;
    cursor: pointer;
    transition: color $transition-time;

    &:hover {
        color: $tertiary!important;
    }
}

// Modal Overlay
.app-modal-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $white;
    z-index: 1000;

    @include media-small {
      background-color: rgba(0, 0, 0, 0.5);
    }
}


// Helpers
.w-200px {
  width: 200px;
}

.resize-none {
  resize: none;
}

.w-fit {
    width: fit-content;
}

.w-max {
    width: max-content;
}

.w-min {
    width: min-content;
}